<template>
  <div class="tc-measure">
    <!-- 排序 -->
    <div class="sort" style="background-color:#ffdec6;">
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="edit(false)"
        >添加量表分组</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="upSort">排序</el-button>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border=""
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="group_name"
          label="分组名称"
          width="350"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_count"
          label="量表统计(份)"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="test_num" label="次数限制" align="center"></el-table-column> -->
        <el-table-column
          prop="status"
          label="分组状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.status == 2 ? 'off' : 'no'"
              @click="upstatus(scope.row)"
              >{{ scope.row.status | statusFilter }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="量表排序" align="center">
          <template slot-scope="scope">
            <!-- <el-input v-bind="scope.row.sort" /> -->
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              @blur="submitSort(scope.row)"
              onkeyup="value=value.replace(/[^\d]/g,'') "
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="edit(scope.row.id)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="remove(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getMeasureGroupList,
  delMeasureGroupList,
  updateMeasureGroupList,
} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      multipleSelection: [],
      tableData: [],
      // 页码
      page: 1,
      size: 15,
      // 总数
      total: 0,
      dialog: {
        state: 1, // 1 添加 2 编辑
        dialogVisible: true,
        form: {
          id: null,
          measure_name: "",
        },
        rules: {
          measure_name: [
            { required: true, message: "请输入分类名称！", trigger: "blur" },
          ],
        },
      },
      scaleData: [], // 存放量表列表的合子
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      measureForm: {
        rules: {},
        search: null,
        name: "", // 分组名
        state: "",
        measureList: "",
        testNum: "",
        showTested: "",
        note: "",
      },
      order: "sort desc",
    };
  },
  created() {
    this.loadMeasureListData();
    // this.loadScaleData();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.loadmeasureListData(val);
    },
    // 刷新
    refresh() {
      this.reload();
    },
    inputNum(val) {},
    // 加载列表
    loadMeasureListData(page) {
      let data = {
        page,
        size: this.size,
        order: this.order,
      };
      getMeasureGroupList(data).then((res) => {
        //console.log(res);

        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    edit(id = false) {
      if (id !== false) {
        this.$router.push(`/measure/do_measure_group?id=${id}`);
      } else {
        this.$router.push(`/measure/do_measure_group`);
      }
    },
    remove(id) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除一个分类数据
          delMeasureGroupList({ id }).then(async (res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.loadMeasureListData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    submitSort(row) {
      //console.log("row", row);
      let param = {
        id: row.id,
        sort: row.sort,
        groupName: row.group_name,
        status: row.status,
      };
      //console.log("param", param);
      this.updateApi(param);
    },
    updateApi(param) {
      updateMeasureGroupList(param).then(async (res) => {
        if (res.code === 400200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.dialog.dialogVisible = false;
        }
        this.loadMeasureListData();
      });
    },
    // 分组状态
    upstatus(row) {
      //console.log(row);
      let data = row;
      if (data.status === 1) {
        data.status = 2;
      } else {
        data.status = 1;
      }
      this.updateApi(data);
    },
    // 数据排序
    upSort() {
      if (this.order == "sort desc") {
        this.order = "sort";
      } else {
        this.order = "sort desc";
      }
      this.loadMeasureListData();
    },
  },
  filters: {
    statusFilter(val) {
      return val == 1 ? "启用" : "关闭";
    },
  },
};
</script>
<style lang="less">
.tc-measure {
  width: 100%;
  min-width: 1300px;
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 180px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  //   排序
  .sort {
    margin-top: 20px;
    height: 55px;
    background-color: rgb(#d9f2f7);
    display: flex;
    align-items: center;
    padding-left: 10px;
    .el-divider--vertical {
      height: 2em;
    }
    .el-button {
      background: #409EFF !important;
      border: none !important;
      color: #fff !important;
      // style="background-color:#ffdec6;"
    }
    // .el-button {
    //   background: transparent;
    //   color: rgb(#044d5a);
    //   font-size: 15px;
    //   border: 1px solid rgb(182, 226, 235);
    //   &:hover {
    //     border: 1px solid rgb(105, 105, 105);
    //   }
    // }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 550px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>